import React from "react";
import AchievementComponent from "./AchievementComponent";

const Achievements = ({ achievements, siteTheme }) => {
	return (
		<div className="flex flex-col items-center justify-center w-full px-2 mx-auto my-40 md:px-5 max-w-max-content-width">
			<div className="relative flex flex-row items-center w-full">
				<div className="mr-2 w-[50%] h-[0.5px] bg-gradient-to-r from-transparent to-linesLight" />
				<p className="text-xs text-center uppercase">Application</p>
				<div className="ml-2 w-[50%] h-[0.5px] bg-gradient-to-r from-linesLight to-transparent" />
			</div>
			<div className="my-5">
				<p
					className={`text-2xl lg:text-4xl font-semibold capitalize font-saira text-center ${siteTheme.textColor}`}>
					Enterprise-Grade Application
				</p>
			</div>
			<div className="columns-1 md:columns-2 xl:columns-3">
				{achievements.map((achievement) => (
					<AchievementComponent achievement={achievement} />
				))}
			</div>
		</div>
	);
};

export { Achievements };
export default Achievements;
