import React from "react";
import TechStackGroup from "./TechStackGroup";

const TechStackRightSummary = ({
	techStacks,
	state,
	siteTheme,
	profileSummaries,
}) => {
	return (
		<div className="flex flex-col w-full gap-10 mx-auto mt-10 mb-40 lg:flex-row max-w-max-content-width md:px-5">
			<div className="flex flex-col items-center justify-center w-full xl:w-[40%]">
				<TechStackGroup data={techStacks.slice(0, 3)} />
				<TechStackGroup data={techStacks.slice(3, 7)} />
				<TechStackGroup data={techStacks.slice(7, 10)} />
			</div>
			<div className="w-full px-5 overflow-hidden xl:w-[60%]">
				<div className="relative flex items-center justify-start w-full">
					<div className="w-1/2 sm:w-2/3 md:w-1/3 flex-nowrap">
						<p
							className={`${siteTheme.textColor} text-[10px] tracking-[0.1em] uppercase font-thin font-saira`}>
							Techstack Summary
						</p>
					</div>
					<div
						className={`w-1/2 sm:w-3/4 md:w-2/3 h-[0.5px] bg-gradient-to-r from-linesLight to-transparent absolute right-0`}
					/>
				</div>
				<div className="mt-10 ml-10">
					<p
						className={`${
							state.isDay ? siteTheme.textColor : "text-neutral-400"
						} font-light text-[14px] tracking-[0.08em] leading-loose mb-10 font-saira`}>
						{profileSummaries.first}
					</p>
					<p
						className={`${
							state.isDay ? siteTheme.textColor : "text-neutral-400"
						} font-light text-[14px] tracking-[0.08em] leading-loose mb-10 font-saira`}>
						{profileSummaries.third}
					</p>
					<p
						className={`${
							state.isDay ? siteTheme.textColor : "text-neutral-400"
						} font-light text-[14px] tracking-[0.08em] leading-loose font-saira`}>
						{profileSummaries.fourth}
					</p>
				</div>
			</div>
		</div>
	);
};

export { TechStackRightSummary };
export default TechStackRightSummary;
