import React from "react";

import { useAppContext } from "../context/ThemeContext";

const TitleAndTextComponent = ({ value }) => {
	const { siteTheme, state } = useAppContext();
	return (
		<div
			className={`flex flex-col items-start gap-4 mb-3 ${siteTheme.cardBackground} break-inside-avoid-column p-5 rounded-2xl`}>
			<div className="flex flex-row items-center gap-4">
				<div className="">
					<p className={`text-base font-saira ${siteTheme.textColor}`}>
						{value?.title}
					</p>
				</div>
			</div>
			<div>
				<p
					className={`${
						state.isDay ? siteTheme.textColor : "text-neutral-400"
					} font-light text-[14px] tracking-[0.08em] leading-loose font-saira`}>
					{value?.text}
				</p>
			</div>
		</div>
	);
};

export { TitleAndTextComponent };
export default TitleAndTextComponent;
