import React from "react";
import { Helmet } from "react-helmet";
import { CiMail } from "react-icons/ci";
import { CiLocationOn } from "react-icons/ci";
import { Formik, Field, Form } from "formik";
import firebase from "firebase/compat/app";
import * as Yup from "yup";
import ClipLoader from "react-spinners/ClipLoader";

import Layout from "../reusable-components/Layout";
import ContactType from "../reusable-components/ContactType";
import { database } from "../config";
import { useAppContext } from "../context/ThemeContext";

function ContactUs() {
	const { state } = useAppContext();
	const [sending, setSending] = React.useState(false);
	const [submitted, setSubmitted] = React.useState(false);
	const ContactSchema = Yup.object().shape({
		name: Yup.string()
			.min(3, "Too Short!")
			.max(50, "Too Long!")
			.required("Required"),
		phone: Yup.string()
			.min(10, "Too Short!")
			.max(10, "Too Long!")
			.required("Required"),
		email: Yup.string().email("Invalid email").required("Required"),
		message: Yup.string().min(10, "Too Short!").required("Required"),
	});

	async function handleRequest(value) {
		setSending(true);

		try {
			await database.collection("contact-requests").add({
				timestamp: firebase.firestore.FieldValue.serverTimestamp(),
				name: value?.name,
				phone: value?.phone,
				email: value?.email,
				message: value?.message,
			});

			// Ensure data is saved before setting `setSending(false)`
			setSending(false);
			setSubmitted(true);
		} catch (error) {
			console.error("Error saving request:", error);
			setSending(false); // Ensure we stop sending even if there's an error
		}
	}

	const override = {
		display: "block",
		margin: "0 auto",
		borderColor: state.isDay ? "text-brandwhite" : "text-deepGrey",
	};

	React.useEffect(() => {
		if (submitted) {
			const submittedId = setTimeout(() => {
				setSubmitted(false);
			}, 3000);

			return () => clearTimeout(submittedId);
		}
	}, [submitted]);

	return (
		<>
			<Helmet>
				<title>Wiingr | Contact-Us</title>
			</Helmet>
			<Layout>
				<div className="flex-col h-full px-4 pt-40 pb-20 mx-auto overflow-hidden font-thin leading-5 text-center max-w-max-content-width md:pt-0">
					<div className="flex flex-col items-end h-[100vh] gap-20 md:flex-row md:justify-center md:gap-2">
						<div className="absolute top-[10%] left-auto w-full">
							<p className="text-[400px] overflow-hidden text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-pink-500 h-64 leading-none opacity-[0.05] font-thin">
								Contact
							</p>
						</div>
						<div className="w-full md:flex-[0.5] z-10">
							<div className="flex flex-col items-start mb-10">
								<p className="mb-3 text-4xl">Get in touch</p>
								<p className="w-1/2 text-xs text-left text-neutral-400">
									Got a question or a special request? Let us know! 😊
								</p>
							</div>
							<div className="flex flex-col gap-y-2 ">
								<ContactType
									link="mailto:info.wiingr@gmail.com"
									component={<CiMail />}
									title="Email"
									value="info.wiingr@gamil.com"
									functionCall={() => {}}
								/>
								<ContactType
									link="https://www.google.com/maps/place/Toronto,+Canada"
									component={<CiLocationOn />}
									title="Location"
									value="Toronto, Canada"
									functionCall={() => {}}
								/>
							</div>
						</div>
						<div className="w-full md:flex-[0.5] p-1 border-[0.5px] border-neutral-700 rounded-2xl bg-[rgba(250,250,250,0.01)] z-10">
							<Formik
								initialValues={{
									name: "",
									phone: "",
									email: "",
									message: "",
								}}
								validationSchema={ContactSchema}
								onSubmit={async (values, { resetForm }) => {
									handleRequest(values);
									resetForm();
								}}>
								{({ errors, touched }) => (
									<Form className="flex flex-col gap-y-2">
										<Field
											id="name"
											name="name"
											placeholder="--- Enter your wiingr account Name ---"
											type="text"
											className={`p-2 border-[0.5px] placeholder:text-center focus:ring-0 focus:border-neutral-600 border-neutral-700 rounded-xl bg-[rgba(250,250,250,0.02)] text-sm ${
												errors.name && touched.name
													? "border-red-900"
													: "border-neutral-700"
											}`}
										/>

										<Field
											id="email"
											name="email"
											placeholder="--- Enter your wiingr account Email ---"
											type="email"
											className={`p-2 border-[0.5px] placeholder:text-center focus:ring-0 focus:border-neutral-600 border-neutral-700 rounded-xl bg-[rgba(250,250,250,0.02)] text-sm ${
												errors.email && touched.email
													? "border-red-900"
													: "border-neutral-700"
											}`}
										/>

										<Field
											id="phone"
											name="phone"
											placeholder="--- Enter your wiingr account Phone ---"
											type="number"
											className={`p-2 border-[0.5px] placeholder:text-center focus:ring-0 focus:border-neutral-600 border-neutral-700 rounded-xl bg-[rgba(250,250,250,0.02)] text-sm ${
												errors.phone && touched.phone
													? "border-red-900"
													: "border-neutral-700"
											}`}
										/>

										<Field name="message">
											{({
												field, // { name, value, onChange, onBlur }
												form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
												meta,
											}) => (
												<div>
													<textarea
														type="text"
														placeholder="--- What can we help you with? 😊 ---"
														row={50}
														{...field}
														className={`p-2 w-full h-64 border-[0.5px] placeholder:text-center focus:ring-0 focus:border-neutral-600 border-neutral-700 rounded-xl bg-[rgba(250,250,250,0.02)] text-sm ${
															meta.touched && meta.error
																? "border-red-900"
																: "border-neutral-700"
														}`}
													/>
												</div>
											)}
										</Field>

										{submitted && (
											<div>
												<p className="text-xs text-green-600 placeholder:text-center">
													Your request has been submitted
												</p>
											</div>
										)}

										{sending ? (
											<ClipLoader
												color={
													state.isDay ? "text-brandBlack" : "text-lightGrey"
												}
												loading={sending}
												cssOverride={override}
												size={20}
												aria-label="Loading Spinner"
												data-testid="loader"
											/>
										) : (
											<div className="w-full mx-auto mb-1">
												<button
													type="submit"
													className={`py-2 w-full px-5 rounded-lg ${
														state.isDay
															? "bg-brandBlack text-lightGrey"
															: "bg-lightGrey text-brandBlack"
													}`}>
													Submit
												</button>
											</div>
										)}
									</Form>
								)}
							</Formik>
						</div>
					</div>
				</div>
			</Layout>
		</>
	);
}

export default ContactUs;
