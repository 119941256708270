import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link as ScrollLink } from "react-scroll";
import { debounce } from "throttle-debounce";
import { BiLinkExternal } from "react-icons/bi";

import Layout from "../reusable-components/Layout";
import HeadParagraph from "../reusable-components/HeadParagraph";
import { useAppContext } from "../context/ThemeContext";
import { mobileMaxWidth } from "../constants";
import { Link } from "react-router-dom";
import { getAllData } from "../utilities/firebase-connection";
import { PlaceholderBar } from "../reusable-components/PlaceholderBar";
import TableComponent from "../reusable-components/TableComponent";

function StatementOfWork() {
	const { siteTheme, state } = useAppContext();
	const [showIcon, setShowIcon] = useState(false);
	const [loading, setLoading] = useState(false);
	const [csa, setCsa] = useState([]);

	function createData(milestone, hours, description) {
		return { milestone, hours, description };
	}

	const rows = [
		createData(
			"Project Setup",
			csa?.timelineMilestone?.project?.hour ?? "***",
			csa?.timelineMilestone?.project?.description ?? "***"
		),
		createData(
			"Frontend Development",
			csa?.timelineMilestone?.frontend?.hour ?? "***",
			csa?.timelineMilestone?.frontend?.description ?? "***"
		),
		createData(
			"Backend Development",
			csa?.timelineMilestone?.backend?.hour ?? "***",
			csa?.timelineMilestone?.backend?.description ?? "***"
		),
		createData(
			"API Integration",
			csa?.timelineMilestone?.api?.hour ?? "***",
			csa?.timelineMilestone?.api?.description ?? "***"
		),
		createData(
			"Testing & Debugging",
			csa?.timelineMilestone?.testingDebugging?.hour ?? "***",
			csa?.timelineMilestone?.testingDebugging?.description ?? "***"
		),
		createData(
			"Deployment & Docs",
			csa?.timelineMilestone?.documentation?.hour ?? "***",
			csa?.timelineMilestone?.documentation?.description ?? "***"
		),
	];

	useEffect(() => {
		const handleScroll = debounce(200, () => {
			const windowWidth = window.innerWidth;
			let cap = 2000;
			if (windowWidth <= mobileMaxWidth) cap = 9000;
			const scrollOffset = window.scrollY || window.pageYOffset;
			if (scrollOffset < cap) setShowIcon(false);
			if (scrollOffset > cap) setShowIcon(true);
		});

		let loadId;
		(async () => {
			setLoading(true);
			const csaData = await getAllData("csa-sow");
			setCsa(csaData[0]);

			loadId = setTimeout(() => {
				setLoading(false);
			}, 2000);
		})();

		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
			clearTimeout(loadId);
		};
	}, []);

	return (
		<>
			<Helmet>
				<title>Wiingr | Statement Of Work</title>
			</Helmet>
			<Layout>
				<main
					id="main"
					className="relative h-[100vh] px-4 pt-16 pb-32 mx-auto font-saira sm:pt-24 max-w-max-content-width overflow-scroll no-scrollbar">
					{showIcon && (
						<ScrollLink
							to="main"
							smooth={true}
							duration={500}
							offset={-100}
							className="cursor-pointer">
							<div
								className={[
									"fixed right-4 bottom-20 sm:right-12 sm:bottom-28 rounded-full w-6 h-6 p-1",
									siteTheme.headingBackground,
								].join(" ")}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth={1.5}
									stroke="currentColor"
									className={[
										"w-full h-full",
										state.isDay ? "text-brandBlack" : "text-brandWhite",
									].join(" ")}>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="m4.5 15.75 7.5-7.5 7.5 7.5"
									/>
								</svg>
							</div>
						</ScrollLink>
					)}

					<h1 className="pt-5 text-3xl font-normal font-saira">
						Statement Of Work (SOW)
					</h1>
					{loading ? (
						<div>
							<div className="mt-5">
								<PlaceholderBar classname="w-1/4 h-[20px] mb-1 placeholder" />
								<PlaceholderBar classname="w-2/4 h-[20px] mb-1 placeholder" />
								<PlaceholderBar classname="w-4/6 h-[20px] mb-1 placeholder" />
								<PlaceholderBar classname="w-full h-[20px] mb-1 placeholder" />
								<PlaceholderBar classname="w-full h-[20px] mb-1 placeholder" />
							</div>

							<div className="mt-5">
								<PlaceholderBar classname="w-1/4 h-[20px] mb-1 placeholder" />
								<PlaceholderBar classname="w-2/4 h-[20px] mb-1 placeholder" />
								<PlaceholderBar classname="w-4/6 h-[20px] mb-1 placeholder" />
								<PlaceholderBar classname="w-full h-[20px] mb-1 placeholder" />
								<PlaceholderBar classname="w-full h-[20px] mb-1 placeholder" />
							</div>

							<div className="mt-5">
								<PlaceholderBar classname="w-1/4 h-[20px] mb-1 placeholder" />
								<PlaceholderBar classname="w-2/4 h-[20px] mb-1 placeholder" />
								<PlaceholderBar classname="w-4/6 h-[20px] mb-1 placeholder" />
								<PlaceholderBar classname="w-full h-[20px] mb-1 placeholder" />
								<PlaceholderBar classname="w-full h-[20px] mb-1 placeholder" />
							</div>
						</div>
					) : (
						<section className="pb-5">
							<HeadParagraph
								HeadComponent={() => (
									<h2 className="pt-2 text-xl font-saira">
										<span className="font-thin">Service:</span>{" "}
										{csa?.deliverable ? csa?.deliverable : " ***"}
										<br />
										<span className="font-thin">Effective Date:</span>{" "}
										{csa?.effectiveDate ? csa?.effectiveDate : " ***"}
										<br />
										<span className="font-thin">Between:</span> Wiingr Nexus
										("Service Provider")
										<br />
										<span className="font-thin">And:</span>{" "}
										{csa?.client ? csa?.client : " ***"}
									</h2>
								)}
								ParagraphComponent={() => (
									<>
										<p className="pt-5 text-base font-thin ">
											<strong> 1. Project Overview</strong>
											<br />
											This Statement of Work (SOW) outlines the scope,
											deliverables, timeline, and payment terms for a FullStack
											Web Development project. The Wiingr will develop a fully
											functional web application, including frontend and backend
											components, within an{" "}
											<strong>{csa?.totalHour ?? "***"}-hours</strong>{" "}
											development timeframe over{" "}
											<strong>{csa?.timeline}</strong>.
										</p>
										<div className="pt-5 text-base font-thin ">
											<strong>2. Scope of Work</strong>
											<ul className="pt-3 text-base font-thin list-inside">
												<li className="list-[disc]">
													Frontend Development: Design and develop the user
													interface using React.js (as agreed upon).
												</li>
												<li className="list-[disc]">
													Backend Development: Implement backend logic, database
													interactions, and API development using Node.js (as
													agreed upon).
												</li>
												<li className="list-[disc]">
													Database Management: Set up and configure the database
													using Google Firebase/FireStore.
												</li>
												<li className="list-[disc]">
													Authentication & Security: Implement user
													authentication, role-based access control, and
													security best practices.
												</li>
												<li className="list-[disc]">
													API Integration: Integrate third-party APIs where
													necessary.
												</li>
												<li className="list-[disc]">
													Testing & Debugging: Perform unit and integration
													testing to ensure a bug-free experience.
												</li>
												<li className="list-[disc]">
													Deployment & Documentation: Deploy the application to
													a staging/production environment and provide necessary
													documentation (Netlify).
												</li>
											</ul>
										</div>
										<div className="pt-5 text-base font-thin ">
											<strong>3. Deliverables </strong>
											<ul className="pt-3 text-base font-thin list-inside">
												<li className="list-[disc]">
													Fully functional web application with frontend and
													backend components.
												</li>
												<li className="list-[disc]">
													Source code stored in a Git repository (GitHub).
												</li>
												<li className="list-[disc]">
													Database schema and API documentation.
												</li>
												<li className="list-[disc]">
													Deployment guide and setup instructions.
												</li>
												<li className="list-[disc]">
													User authentication system.
												</li>
												<li className="list-[disc]">
													Basic UI/UX design implementation.
												</li>
											</ul>
										</div>
										<div className="pt-5 text-base font-thin ">
											<strong>4. Timeline & Milestones</strong>
											<TableComponent
												rows={rows}
												siteTheme={siteTheme}
												state={state}
											/>
										</div>
										<div className="pt-5 text-base font-thin ">
											<strong>5. Payment Terms </strong>
											<p className="text-base font-thin ">
												The total project budget will be{" "}
												<strong>
													{csa?.perHourRate ?? "***"} per hour x{" "}
													{csa?.totalHour ?? "***"} = ${csa?.charge ?? "***"}
												</strong>
												. Payments will be structured as follows:
											</p>
											<ul className="pt-3 text-base font-thin list-inside">
												<li className="list-[disc]">
													<strong>
														{csa?.paymentSchedule?.signing ?? "***"}%{" "}
													</strong>
													upfront before project commencement.
												</li>
												<li className="list-[disc]">
													<strong>
														{csa?.paymentSchedule?.approval ?? "***"}%{" "}
													</strong>{" "}
													upon completion of Frontend & Backend Development.
												</li>
												<li className="list-[disc]">
													<strong>
														{csa?.paymentSchedule?.complete ?? "***"}%{" "}
													</strong>
													upon final delivery and deployment.
												</li>
											</ul>
										</div>
										<div className="pt-5 text-base font-thin ">
											<strong>6. Assumptions & Constraints </strong>
											<br />
											<ul className="pt-3 text-base font-thin list-inside">
												<li className="list-[disc]">
													The client will provide necessary third-party API
													keys, hosting credentials, and branding assets.
												</li>
												<li className="list-[disc]">
													Any additional features outside the agreed scope will
													require a separate agreement.
												</li>
												<li className="list-[disc]">
													The Wiingr will communicate progress via Email.
												</li>
											</ul>
										</div>
										<div className="pt-5 text-base font-thin ">
											<strong>7. Acceptance Criteria</strong>
											<br />
											<ul className="pt-3 text-base font-thin list-inside">
												<li className="list-[disc]">
													The application must function as per agreed
													requirements.
												</li>
												<li className="list-[disc]">
													All major bugs and issues should be resolved before
													final delivery.
												</li>
												<li className="list-[disc]">
													The deployment should be successful on the agreed
													hosting platform.
												</li>
											</ul>
										</div>
										<p className="pt-5 text-base font-thin ">
											<strong>8. Termination Clause</strong>
											<br />
											Either party may terminate this agreement with a{" "}
											<strong>
												{csa?.terminationNoticePeriod ?? "***"}
											</strong>{" "}
											written notice. Work completed up to the termination date
											will be billed accordingly.
										</p>
									</>
								)}
							/>
							{csa?.files?.length && (
								<div
									className={`mt-5 ${siteTheme.toolBackground} w-fit ${siteTheme.toolText} px-3 py-1 rounded-full text-sm flex flex-row items-center`}>
									<Link to={csa?.files?.[1]} target="_blank">
										Click to download e-copy
									</Link>
									<BiLinkExternal size={12} className="ml-1" />
								</div>
							)}
						</section>
					)}
				</main>
			</Layout>
		</>
	);
}

export default StatementOfWork;
