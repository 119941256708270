const mobileMaxWidth = 767;
const hirePathname = "/nexus/hire-us";
const contractingPathname = "/nexus/contracting";
const nexusterms = "/nexus/terms";
const clientServicesAgreement = "/nexus/csa";
const statementOfWork = "/nexus/sow";

export {
	mobileMaxWidth,
	hirePathname,
	contractingPathname,
	nexusterms,
	clientServicesAgreement,
	statementOfWork,
};
