import React from "react";
import { BiLinkExternal } from "react-icons/bi";
import { FaSquareGithub } from "react-icons/fa6";
import { RxLinkedinLogo } from "react-icons/rx";
import { Link } from "react-router-dom";

const FloatLinks = ({ otherInfo, siteTheme }) => {
	return (
		<div className="fixed flex flex-row items-center bottom-16 right-10 sm:bottom-20 sm:right-20 p-3 bg-[rgba(17,17,17,0.3)]">
			<Link to={otherInfo?.linkedin} target="_blank">
				<RxLinkedinLogo size={28} className="mr-4" />
			</Link>
			<Link to={otherInfo?.github} target="_blank">
				<FaSquareGithub size={28} className="mr-5" />
			</Link>
			<div
				className={`${siteTheme.toolBackground} w-fit ${siteTheme.toolText} px-3 py-[3px] rounded-sm text-sm flex flex-row items-center`}>
				<Link to={otherInfo?.resume} target="_blank">
					Download resumé
				</Link>
				<BiLinkExternal size={12} className="ml-1" />
			</div>
		</div>
	);
};

export { FloatLinks };
export default FloatLinks;
