import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import { CiMail } from "react-icons/ci";
import { CiLocationOn } from "react-icons/ci";
import { TiWarningOutline } from "react-icons/ti";
import { Formik, Field, Form } from "formik";
import firebase from "firebase/compat/app";
import * as Yup from "yup";
import ClipLoader from "react-spinners/ClipLoader";

import Layout from "../reusable-components/Layout";
import ContactType from "../reusable-components/ContactType";
import { app, database } from "../config";
import { useAppContext } from "../context/ThemeContext";
import { Link } from "react-router-dom";

function HireWiingr() {
	const fileInputRef = useRef();
	const { siteTheme, state } = useAppContext();
	const [sending, setSending] = React.useState(false);
	const [submitted, setSubmitted] = React.useState(false);
	const [files, setFiles] = React.useState(null);
	const [progress, setProgress] = React.useState(0);
	const HireSchema = Yup.object().shape({
		name: Yup.string()
			.min(3, "Too Short!")
			.max(50, "Too Long!")
			.required("Required"),
		website: Yup.string().url("Invalid URL format").required("Required"),
		email: Yup.string().email("Invalid email").required("Required"),
		message: Yup.string().min(10, "Too Short!").required("Required"),
	});

	async function handleRequest(value) {
		setSending(true);

		try {
			if (!files?.length) {
				await database.collection("hire-requests").add({
					timestamp: firebase.firestore.FieldValue.serverTimestamp(),
					companyname: value?.name,
					website: value?.website,
					email: value?.email,
					message: value?.message,
				});
			} else {
				const uploadPromises = files.map((file, index) => {
					const storageRef = app
						?.storage()
						?.ref(`hire/${Date.now()}-${file.name}`);
					const metadata = { contentType: file.type };

					return new Promise((resolve, reject) => {
						storageRef.put(file, metadata).on(
							"state_changed",
							(snapshot) => {
								const progress = Math.round(
									(snapshot.bytesTransferred / snapshot.totalBytes) * 100
								);
								setProgress(progress.toFixed(0));
							},
							(error) => {
								reject(error);
								setProgress(0);
							},
							async () => {
								const downloadURL = await storageRef.getDownloadURL();
								resolve(downloadURL);
							}
						);
					});
				});

				Promise.all(uploadPromises)
					.then(async (downloadURLs) => {
						await database.collection("hire-requests").add({
							timestamp: firebase.firestore.FieldValue.serverTimestamp(),
							companyname: value?.name,
							website: value?.website,
							email: value?.email,
							message: value?.message,
							files: downloadURLs,
						});

						setSending(false);
						setSubmitted(true);
						fileInputRef.current.value = "";
					})
					.catch((error) => {
						console.error("Upload failed:", error);
						setSending(false);
					});
			}
			// Ensure data is saved before setting `setSending(false)`
			setSending(false);
			setSubmitted(true);
		} catch (error) {
			console.error("Error saving request:", error);
			setSending(false); // Ensure we stop sending even if there's an error
		}
	}

	const override = {
		display: "block",
		margin: "0 auto",
		borderColor: state.isDay ? "text-brandwhite" : "text-deepGrey",
	};

	React.useEffect(() => {
		if (submitted || progress) {
			const submittedId = setTimeout(() => {
				setSubmitted(false);
				setProgress(0);
			}, 3000);

			return () => clearTimeout(submittedId);
		}
	}, [progress, submitted]);

	const handleFileChange = (e) => {
		const selectedFile = Array.from(e.target.files);

		const validFiles = selectedFile.filter(
			(file) =>
				file.type === "application/pdf" ||
				file.type.includes("document") ||
				file.name.endsWith(".docx") ||
				file.name.endsWith(".doc")
		);

		if (validFiles.length > 0) {
			setFiles(selectedFile);
		} else {
			alert("Please upload a PDF or document file.");
			e.target.value = null;
		}
	};

	return (
		<>
			<Helmet>
				<title>Wiingr | Nexus:Hire-us</title>
			</Helmet>
			<Layout>
				<div className="flex-col h-full px-4 pt-40 mx-auto overflow-hidden font-thin leading-5 text-center font-saira max-w-max-content-width md:pt-20 pb-14">
					<div className="flex flex-col items-end h-full gap-20 md:flex-row md:justify-center md:gap-2">
						<div className="absolute top-[10%] left-auto">
							<p className="text-[300px] text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-pink-500 h-52 leading-none opacity-[0.05] font-thin">
								Hire us
							</p>
						</div>
						<div className="w-full md:flex-[0.5] z-10">
							<div className="flex flex-col items-start mb-14">
								<p className="text-4xl tracking-[0.15em] leading-none text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-pink-500">
									AMPLIFY
								</p>
								<p className="mb-3 text-3xl text-neutral-400 tracking-[0.05em]">
									your presence
								</p>
								<p className="w-1/2 text-xs text-left text-neutral-400">
									Let us turn your vision into reality!
								</p>
								<p className="w-1/2 text-xs italic text-[9px] text-left text-neutral-400">
									with our expertise, your success is just the beginning.
								</p>
							</div>
							<div className="flex flex-col gap-y-2 ">
								<ContactType
									link="mailto:info.wiingr@gmail.com"
									component={<CiMail />}
									title="Email"
									value="info.wiingr@gamil.com"
									functionCall={() => {}}
								/>
								<ContactType
									link="https://www.google.com/maps/place/Toronto,+Canada"
									component={<CiLocationOn />}
									title="Location"
									value="Toronto, Canada"
									functionCall={() => {}}
								/>
							</div>
						</div>
						<div className="w-full md:flex-[0.5] p-1 border-[0.5px] border-neutral-700 rounded-2xl bg-[rgba(250,250,250,0.01)] z-10">
							<Formik
								initialValues={{
									name: "",
									website: "",
									email: "",
									message: "",
								}}
								validationSchema={HireSchema}
								onSubmit={async (values, { resetForm }) => {
									console.log("first");
									handleRequest(values);
									resetForm();
								}}>
								{({ errors, touched }) => (
									<Form className="flex flex-col gap-y-2">
										<Field
											id="name"
											name="name"
											placeholder="--- Enter your company's name ---"
											type="text"
											className={`p-2 border-[0.5px] placeholder:text-center focus:ring-0 focus:border-neutral-600 border-neutral-700 rounded-xl bg-[rgba(250,250,250,0.02)] text-sm ${
												errors.name && touched.name
													? "border-red-900"
													: "border-neutral-700"
											}`}
										/>

										<Field
											id="website"
											name="website"
											placeholder="--- Enter your company's LinkedIn / Social profile link ---"
											type="text"
											className={`p-2 border-[0.5px] placeholder:text-center focus:ring-0 focus:border-neutral-600 border-neutral-700 rounded-xl bg-[rgba(250,250,250,0.02)] text-sm ${
												errors.website && touched.website
													? "border-red-900"
													: "border-neutral-700"
											}`}
										/>

										<Field
											id="email"
											name="email"
											placeholder="--- Enter your company's Email ---"
											type="email"
											className={`p-2 border-[0.5px] placeholder:text-center focus:ring-0 focus:border-neutral-600 border-neutral-700 rounded-xl bg-[rgba(250,250,250,0.02)] text-sm ${
												errors.email && touched.email
													? "border-red-900"
													: "border-neutral-700"
											}`}
										/>

										<Field name="message">
											{({
												field, // { name, value, onChange, onBlur }
												form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
												meta,
											}) => (
												<div>
													<textarea
														type="text"
														placeholder="--- How can we be of service ? ---"
														row={50}
														{...field}
														className={`p-2 w-full h-64 border-[0.5px] placeholder:text-center focus:ring-0 focus:border-neutral-600 border-neutral-700 rounded-xl bg-[rgba(250,250,250,0.02)] text-sm ${
															meta.touched && meta.error
																? "border-red-900"
																: "border-neutral-700"
														}`}
													/>
												</div>
											)}
										</Field>

										<div>
											<div className="flex flex-row mb-[5px] lg:items-start">
												<input
													ref={fileInputRef}
													type="file"
													multiple
													accept=".pdf,.doc,.docx"
													onChange={handleFileChange}
													className={`block w-full text-sm file:mr-4 file:py-2 file:px-4 file:rounded-tl-2xl file:rounded-tr-2xl file:rounded-br-2xl file:border-0 file:text-sm file:font-semibold ${
														state.isDay
															? "file:bg-brandWhite file:text-brandBlack"
															: "file:bg-linesLighter file:text-brandWhite"
													} w-full`}
												/>
												{progress > 0 && (
													<div className="w-full my-2 text-sm text-blue-400 lg:my-0 lg:ml-5">
														<p>Upload Status: {progress}%</p>
													</div>
												)}
											</div>
											<div
												className={`flex items-center p-2 mb-2 rounded-tr-2xl rounded-bl-2xl rounded-br-2xl w-fit text-yellow-500 ${
													state.isDay ? "bg-brandWhite" : "bg-linesLighter"
												}`}>
												<TiWarningOutline size={22} />
												<p
													className={`text-xs text-start font-normal tracking-widest ml-1`}>
													Please note that only PDF, DOC, and DOCX file formats
													are accepted.
												</p>
											</div>
										</div>

										{submitted && (
											<div>
												<p className="text-xs text-green-600 placeholder:text-center">
													Your request has been submitted
												</p>
											</div>
										)}

										{sending ? (
											<ClipLoader
												color={
													state.isDay ? "text-brandBlack" : "text-lightGrey"
												}
												loading={sending}
												cssOverride={override}
												size={20}
												aria-label="Loading Spinner"
												data-testid="loader"
											/>
										) : (
											<div className="w-full mx-auto">
												<button
													type="submit"
													className={`py-2 w-full px-5 rounded-lg ${
														state.isDay
															? "bg-brandBlack text-lightGrey"
															: "bg-lightGrey text-brandBlack"
													}`}>
													Submit
												</button>
											</div>
										)}

										<div className="my-2">
											<p
												className={`${siteTheme.textColor} font-medium tracking-wider text-xs`}>
												By submitting this form, you agree to Wiingr Nexus{" "}
												<Link to="/nexus/terms" className="underline">
													Terms and Conditions
												</Link>
											</p>
										</div>
									</Form>
								)}
							</Formik>
						</div>
					</div>
				</div>
			</Layout>
		</>
	);
}

export default HireWiingr;
