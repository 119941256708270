import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../reusable-components/Layout";
import { useAppContext } from "../context/ThemeContext";
import Spiral from "../../src/reusable-components/Spiral"

function HomePage() {
	const { siteTheme } = useAppContext();
	return (
		<>
			<Helmet>
				<title>Wiingr | Home</title>

			</Helmet>
			<Layout>
				<div className="relative flex w-full h-[100vh] px-4 pt-32 pb-20 mx-auto max-w-max-content-width md:justify-center overflow-hidden">
					<div className="relative flex items-center justify-end w-full h-[400px] ">
						<div className="absolute bottom-0 left-0 right-0 z-20">
							<span className={`${siteTheme.textColor} absolute top-[23%] left-20 text-2xl tracking-[0.5em] font-thin drop-shadow-[0_0_5px_#ffea00]`}>...think</span>
							<p className="text-[276px] text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-pink-500 h-[180px] leading-none opacity-[0.3] font-thin drop-shadow-[0_0_10px_#ffea00]">
								innovation
							</p>
						</div>
						<Spiral siteTheme={siteTheme} />
					</div>
				</div>
			</Layout>
		</>
	);
}

export default HomePage;
