import React from 'react'

const Spiral = ({siteTheme}) => {
  return (
    <div className="opacity-[0.3]">
      <div className={`z-20 drop-shadow-[0_0_10px_#ffea00] -rotate-[120deg] absolute -top-[150px] -right-[180px] w-[500px] h-[500px] ${siteTheme.borderBannerColor} border-t-[1px] rounded-full`} />
      <div className={`z-20 drop-shadow-[0_0_10px_#ffea00] -rotate-[120deg] absolute -top-[130px] -right-[110px]  w-[400px] h-[400px] ${siteTheme.borderBannerColor} border-t-[1px] rounded-full`} />
      <div className={`z-20 drop-shadow-[0_0_10px_#ffea00] -rotate-[120deg] absolute -top-[100px] -right-[35px] w-[300px] h-[300px] ${siteTheme.borderBannerColor} border-t-[1px] rounded-full`} />
      <div className={`z-20 drop-shadow-[0_0_10px_#ffea00] -rotate-[120deg] absolute -top-[80px] right-[35px] w-[200px] h-[200px] ${siteTheme.borderBannerColor} border-t-[1px] rounded-full`} />
      <div className={`z-20 drop-shadow-[0_0_10px_#ffea00] -rotate-[120deg] absolute -top-[65px] right-[105px] w-[100px] h-[100px] ${siteTheme.borderBannerColor} border-t-[1px] rounded-full`} />
    </div>
  )
}

export { Spiral }
export default Spiral
