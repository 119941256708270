import React from "react";
import { BiLinkExternal } from "react-icons/bi";

import { useAppContext } from "../context/ThemeContext";
import { Link } from "react-router-dom";
import { getDate } from "../utilities/formatTimestamp";

const ContractingReqeustComponent = ({ request }) => {
	const { siteTheme, state } = useAppContext();
	return (
		<div
			className={`flex flex-col items-start gap-4 mb-3 w-full ${siteTheme.cardBackground} break-inside-avoid-column p-5 rounded-2xl`}>
			<div className="flex flex-col items-start w-full">
				<div className="flex flex-row items-center justify-between w-full">
					<p className={`text-base font-saira ${siteTheme.textColor}`}>
						{request?.companyname}
					</p>
					<Link
						to={request?.website}
						target="_blank"
						className="flex flex-row items-center cursor-pointer">
						<span className="mr-1 text-xs text-blue-500 font-saira">
							View website
						</span>
						<BiLinkExternal size={12} className="text-blue-500" />
					</Link>
				</div>
				<div className="flex flex-col items-start">
					<Link to={`mailto:${request?.email}`}>
						<span className="text-neutral-500">{request?.email}</span>
					</Link>
					<div className="mt-1 text-xs text-neutral-500">
						Request date: {getDate(request?.timestamp)}
					</div>
					{request?.files?.map((file, idx) => (
						<Link
							key={idx}
							to={file}
							target="_blank"
							className="flex flex-row items-center mt-1 text-xs text-blue-500">
							<p>Attachment</p>
							<BiLinkExternal size={12} className="ml-1" />
						</Link>
					))}
				</div>
			</div>
			<div className="w-full">
				<p
					className={`${
						state.isDay ? siteTheme.textColor : "text-neutral-400"
					} font-light text-[14px] tracking-[0.05em] font-saira`}>
					{request?.message}
				</p>
			</div>
		</div>
	);
};

export { ContractingReqeustComponent };
export default ContractingReqeustComponent;
