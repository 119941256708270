import React from 'react'
import { GoArrowUpRight } from 'react-icons/go'
import { Link } from 'react-router-dom'

function ContactType({component, title, value, link}) {
  return (
    <div className="p-2 border-[0.5px] border-neutral-700 rounded-2xl bg-[rgba(250,250,250,0.03)] inset-shadow-sm w-full flex items-center flex-row cursor-pointer">
      <div className="border-[0.5px] border-neutral-700 w-fit p-1 rounded-lg">
         {component}
      </div>
      <div className="flex flex-row items-center flex-1 ml-3">
         <div className="flex-1">
            <p className="text-xs font-semibold text-start">{title}</p>
            <p className="text-xs text-start">{value}</p>
         </div>
         <Link
            to={link ?? "/"}
            target="_blank"
            className="w-fit bg-[rgba(250,250,250,0.1)] p-1 rounded-full">
            <GoArrowUpRight size={14} />
         </Link>
      </div>
   </div>
  )
}

export default ContactType