import React from "react";
import TitleAndTextComponent from "./TitleAndTextComponent";
import TechStacks from "./TechStacks";

const MicroFrontEnd = ({ mfe, siteTheme, state }) => {
	return (
		<div className="flex flex-col items-center justify-center w-full px-2 mx-auto my-40 md:px-5 max-w-max-content-width">
			<div className="relative flex flex-row items-center w-full">
				<div className="mr-2 flex-[0.5] md:flex-[0.4] h-[0.5px] bg-gradient-to-r from-transparent to-linesLight" />
				<p className="flex-[0.5] md:flex-[0.2] text-xs text-center uppercase w-fit">
					Deep Tech Stack Proficiency
				</p>
				<div className="ml-2 flex-[0.5] md:flex-[0.4] h-[0.5px] bg-gradient-to-r from-linesLight to-transparent" />
			</div>
			<div className="my-5">
				<p
					className={`text-2xl lg:text-4xl font-semibold capitalize font-saira text-center ${siteTheme.textColor}`}>
					{mfe.title}
				</p>
			</div>
			<div className="flex flex-col w-full gap-10 mx-auto my-10 lg:flex-row max-w-max-content-width">
				<div className="w-full px-5 overflow-hidden lg:w-1/2">
					<div className="">
						<div className="relative flex items-center justify-start w-full">
							<div className="w-fit">
								<p
									className={`${siteTheme.textColor} text-[10px] tracking-[0.1em] uppercase font-thin font-saira`}>
									Summary
								</p>
							</div>
							<div
								className={`w-full h-[0.5px] bg-gradient-to-r from-linesLight to-transparent absolute left-16`}
							/>
						</div>
						<div className="mt-10 ml-10">
							<p
								className={`${
									state.isDay ? siteTheme.textColor : "text-neutral-400"
								} font-light text-[14px] tracking-[0.08em] leading-loose mb-10 font-saira`}>
								{mfe.summary}
							</p>
						</div>
					</div>
					<div className="">
						<div className="relative flex items-center justify-start w-full">
							<div className="w-fit">
								<p
									className={`${siteTheme.textColor} text-[10px] tracking-[0.1em] uppercase font-thin font-saira`}>
									{mfe?.whyMFE?.question}
								</p>
							</div>
							<div
								className={`w-full h-[0.5px] bg-gradient-to-r from-linesLight to-transparent absolute left-36`}
							/>
						</div>
						<div className="mt-10 ml-10">
							<p
								className={`${
									state.isDay ? siteTheme.textColor : "text-neutral-400"
								} font-light text-[14px] tracking-[0.08em] leading-loose mb-10 font-saira`}>
								{mfe?.whyMFE?.answer}
							</p>
						</div>
					</div>
				</div>
				<div className="w-full lg:w-1/2">
					<div className="relative px-5 lg:px-0">
						<p
							className={`${siteTheme.textColor} text-[10px] tracking-[0.1em] uppercase font-thin font-saira`}>
							{mfe?.techStacksTools?.section}
						</p>
						<div
							className={`w-full h-[0.5px] bg-gradient-to-r from-linesLight to-transparent absolute left-44 lg:left-36 top-[50%]`}
						/>
					</div>

					<div className="grid items-center justify-center w-full grid-cols-1 gap-2 px-5 mt-10 md:grid-cols-2 md:px-0">
						<TechStacks
							value={mfe?.techStacksTools?.api}
							widthAndheight="w-[80%] sm:w-full lg:w-[300px] h-[200px]"
							notRoundImg={true}
						/>
						<TechStacks
							value={mfe?.techStacksTools?.deployment}
							widthAndheight="w-[80%] sm:w-full lg:w-[300px] h-[200px]"
							notRoundImg={true}
						/>
						<TechStacks
							value={mfe?.techStacksTools?.languagesAndFrameworks}
							widthAndheight="w-[80%] sm:w-full lg:w-[300px] h-[200px]"
							notRoundImg={true}
						/>
						<TechStacks
							value={mfe?.techStacksTools?.microFrontendTools}
							widthAndheight="w-[80%] sm:w-full lg:w-[300px] h-[200px]"
							notRoundImg={true}
						/>
						<TechStacks
							value={mfe?.techStacksTools?.stateManagement}
							widthAndheight="w-[80%] sm:w-full lg:w-[300px] h-[200px]"
							notRoundImg={true}
						/>
					</div>
				</div>
			</div>
			<div className="flex flex-col items-center justify-center w-full px-2 mx-auto my-10 md:px-5 max-w-max-content-width">
				<div className="relative flex flex-row items-center w-full mx-auto mb-5">
					<div className="mr-2 flex-[0.5] h-[0.5px] bg-gradient-to-r from-transparent to-linesLight" />
					<p
						className={`${siteTheme.textColor} text-[10px] tracking-[0.1em] uppercase font-thin font-saira`}>
						{mfe?.keySkillsAndExperise?.section}
					</p>
					<div className="ml-2 flex-[0.5] h-[0.5px] bg-gradient-to-r from-linesLight to-transparent" />
				</div>
				<div className="columns-1 md:columns-2 xl:columns-3">
					<TitleAndTextComponent value={mfe?.keySkillsAndExperise?.agbc} />
					<TitleAndTextComponent value={mfe?.keySkillsAndExperise?.ctc} />
					<TitleAndTextComponent value={mfe?.keySkillsAndExperise?.ideploy} />
					<TitleAndTextComponent value={mfe?.keySkillsAndExperise?.mfa} />
					<TitleAndTextComponent value={mfe?.keySkillsAndExperise?.mfw} />
					<TitleAndTextComponent value={mfe?.keySkillsAndExperise?.monorepos} />
					<TitleAndTextComponent value={mfe?.keySkillsAndExperise?.po} />
					<TitleAndTextComponent value={mfe?.keySkillsAndExperise?.smmfe} />
					<TitleAndTextComponent value={mfe?.keySkillsAndExperise?.spa} />
				</div>
			</div>
		</div>
	);
};

export { MicroFrontEnd };
export default MicroFrontEnd;
