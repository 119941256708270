import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link as ScrollLink } from "react-scroll";
import { debounce } from "throttle-debounce";

import Layout from "../reusable-components/Layout";
import HeadParagraph from "../reusable-components/HeadParagraph";
import { useAppContext } from "../context/ThemeContext";
import { mobileMaxWidth } from "../constants";
import { Link } from "react-router-dom";

function NexusTermsOfService() {
	const { siteTheme, state } = useAppContext();
	const [showIcon, setShowIcon] = useState(false);

	useEffect(() => {
		const handleScroll = debounce(200, () => {
			const windowWidth = window.innerWidth;
			let cap = 2000;
			if (windowWidth <= mobileMaxWidth) cap = 9000;
			const scrollOffset = window.scrollY || window.pageYOffset;
			if (scrollOffset < cap) setShowIcon(false);
			if (scrollOffset > cap) setShowIcon(true);
		});

		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);
	return (
		<>
			<Helmet>
				<title>Wiingr | Nexus Terms</title>
			</Helmet>
			<Layout>
				<main
					id="main"
					className="relative px-4 pt-16 pb-32 mx-auto font-saira sm:pt-24 max-w-max-content-width">
					{showIcon && (
						<ScrollLink
							to="main"
							smooth={true}
							duration={500}
							offset={-100}
							className="cursor-pointer">
							<div
								className={[
									"fixed right-4 bottom-20 sm:right-12 sm:bottom-28 rounded-full w-6 h-6 p-1",
									siteTheme.headingBackground,
								].join(" ")}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth={1.5}
									stroke="currentColor"
									className={[
										"w-full h-full",
										state.isDay ? "text-brandBlack" : "text-brandWhite",
									].join(" ")}>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="m4.5 15.75 7.5-7.5 7.5 7.5"
									/>
								</svg>
							</div>
						</ScrollLink>
					)}

					<h1 className="pt-5 text-3xl font-normal font-saira">
						Terms of service
					</h1>
					<section className="pb-5 ">
						<HeadParagraph
							HeadComponent={() => (
								<h2 className="pt-2 text-xl font-saira">
									Company Name: Wiingr Nexus
								</h2>
							)}
							ParagraphComponent={() => (
								<>
									<p className="pt-5 text-base font-thin ">
										Welcome to Wiingr Nexus ("Company," "we," "us," or "our"). We provide web and mobile application design, development, and deployment solutions (the "Services"). By engaging our Services, you ("Client," "you") agree to the following Terms of Service.
										<br />
									</p>
									<p className="pt-5 text-base font-thin ">
										<strong> 1. Scope of Services </strong>
										<br />
										We agree to provide professional web and mobile application solutions based on the specifications mutually agreed upon in the project proposal or Statement of Work (SOW). Any additional requests or changes beyond the agreed scope may require a separate agreement and additional fees.
									</p>
									<p className="pt-5 text-base font-thin ">
										<strong>2. Client Responsibilities </strong>
										<ul className="pt-3 text-base font-thin list-inside">
											<li className="list-[disc]">
												Provide timely access to project materials, information, and approvals necessary for us to perform the Services.
											</li>
											<li className="list-[disc]">
												Ensure all materials supplied (content, images, data) are owned or licensed by you.
											</li>
											<li className="list-[disc]">
												Promptly review and approve deliverables.
											</li>
										</ul>
									</p>
									<p className="pt-5 text-base font-thin ">
										<strong>3. Payment Terms </strong>
										<ul className="pt-3 text-base font-thin list-inside">
											<li className="list-[disc]">
												Payment structure, milestones, and due dates will be defined in the project proposal or SOW.
											</li>
											<li className="list-[disc]">
												All invoices are due within [15/30] days unless otherwise agreed in writing.
											</li>
											<li className="list-[disc]">
												Late payments may incur a 1.5% monthly interest fee or the maximum allowed by law.
											</li>
										</ul>
									</p>
									<p className="pt-5 text-base font-thin ">
										<strong>4. Intellectual Property (IP) </strong>
										<ul className="pt-3 text-base font-thin list-inside">
											<li className="list-[disc]">
												All original work created by us during the project will remain our property until full payment is received.
											</li>
											<li className="list-[disc]">
												Upon final payment, you receive a non-exclusive, non-transferable license to use the deliverables as outlined in the project scope.
											</li>
											<li className="list-[disc]">
												We retain the right to showcase non-confidential work in our portfolio or marketing materials unless otherwise agreed.
											</li>
										</ul>
									</p>
									<p className="pt-5 text-base font-thin ">
										<strong>5. Confidentiality </strong>
										Both parties agree to maintain the confidentiality of proprietary or sensitive information shared during the project. This obligation survives the termination of the agreement.
										<br />
									</p>
									<p className="pt-5 text-base font-thin ">
										<strong>6. Warranties & Disclaimers </strong>
										<br />
										<ul className="pt-3 text-base font-thin list-inside">
											<li className="list-[disc]">
												We warrant that the deliverables will substantially conform to the agreed specifications.
											</li>
											<li className="list-[disc]">
												We do not guarantee that the software will be error-free or operate without interruption.
											</li>
											<li className="list-[disc]">
												Except as explicitly stated, the Services are provided "as is" without additional warranties, express or implied.
											</li>
										</ul>
									</p>
									<p className="pt-5 text-base font-thin ">
										<strong>7. Limitation of Liability</strong>
										<br />
										To the maximum extent permitted by law, our total liability for any claim arising out of or relating to this agreement is limited to the total amount paid by you for the Services in question.
										<br />
										We are not liable for indirect, consequential, incidental, or special damages, including loss of profits or business opportunities.
									</p>
									<p className="pt-5 text-base font-thin ">
										<strong>8. Termination</strong>
										<br />
										<ul className="pt-3 text-base font-thin list-inside">
											<li className="list-[disc]">
												Either party may terminate the project with [15/30] days' written notice.
											</li>
											<li className="list-[disc]">
												Upon termination, you agree to pay for all work completed up to the termination date.
											</li>
											<li className="list-[disc]">
												Sections regarding Payment, IP, Confidentiality, and Limitation of Liability will survive termination.
											</li>
										</ul>
									</p>
									<p className="pt-5 text-base font-thin ">
										<strong>9. Third-Party Tools & Integrations</strong>
										<br />
										We may use third-party services, APIs, or tools during development. We are not liable for downtime or issues caused by third-party providers.
									</p>
									<p className="pt-5 text-base font-thin ">
										<strong>10. Governing Law & Dispute Resolution</strong>
										<br />
										These Terms are governed by the laws of [Your Country/State]. Any disputes shall be resolved through good-faith negotiations. If unresolved, disputes will be subject to binding arbitration in [Your City/Region].
									</p>
									<p className="pt-5 text-base font-thin ">
										<strong>11. Updates to Terms</strong>
										<br />
										We may update these Terms periodically. Continued use of our Services after such updates constitutes your acceptance of the revised Terms.
									</p>
									<p className="pt-5 text-base font-thin ">
										<strong>12. Entire Agreement</strong>
										<br />
										This document, along with the signed project proposal or SOW, constitutes the entire agreement between both parties and supersedes any prior discussions or agreements.
									</p>
									<p className="pt-5 text-base font-thin ">
										<strong>Contact Information</strong>
										<br />
										For questions regarding these Terms of Service, please contact:
										<br />
										<span>📧 <Link to="mailto:info.wiingr@gmail.com" className="duration-300 ease-in hover:underline hover:text-blue-600">info.wiingr@gmail.com</Link></span>
										<br />
										<span>📍 <Link to="https://www.google.com/maps/place/Toronto,+Canada" className="duration-300 ease-in hover:underline hover:text-blue-600">Toronto, ON. Canada</Link></span>
										
									</p>
								</>
							)}
						/>
					</section>
				</main>
			</Layout>
		</>
	);
}

export default NexusTermsOfService;
