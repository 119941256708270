import React from 'react'
import Layout from './Layout'
import { Helmet } from 'react-helmet'

const Loading = ({isNotavailable}) => {
  return (
   <>
      <Helmet>
				<title>Wiingr | Loading</title>

			</Helmet>
			<Layout>
            <div className="flex items-center justify-center h-[100vh] max-w-max-content-width w-[80%] lg:w-[60%] mx-auto">
              {!isNotavailable 
              ? 
                  <p className="text-3xl font-thin !leading-relaxed text-transparent md:text-6xl font-saira bg-clip-text bg-gradient-to-r from-cyan-500 to-pink-500">Loading...</p> 
               : 
                  <p className="text-lg font-thin !leading-relaxed text-center text-transparent md:text-2xl font-saira bg-clip-text bg-gradient-to-r from-cyan-500 to-pink-500">This page is temporarily unavailable. If urgent, please reach out via the <a href="/contact-us" target="self" className="font-normal duration-300 ease-in hover:underline hover:text-neutral-500">Contact Us</a> page. Thank you!</p>
              }
            </div>
         </Layout>
   </>
  )
}

export default Loading
