import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const TableComponent = ({ rows, siteTheme, state }) => {
	return (
		<div className="mt-3 w-fit max-w-[100%] lg:max-w-[80%]">
			<DataTable
				value={rows}
				showGridlines={true}
				stripedRows={true}
				draggable={true}
				tableStyle={{
					minWidth: "50rem",
				}}
				rowClassName={(_rowData, index) => {
					const dataValue = index.props.value;
					dataValue.map((_value, index) => {
						return index % 2 === 0 ? "even-row" : "odd-row";
					});
				}}>
				<Column
					field="milestone"
					header="Milestone"
					className={`${siteTheme.headerBorder} border-[0.5px] p-2`}
					headerStyle={{
						padding: "12px",
						border: `0.5px solid ${
							state?.isDay ? "rgba(117, 117, 117, 0.5)" : "#171e25"
						}`,
					}}></Column>
				<Column
					field="hours"
					header="Hours"
					className={`${siteTheme.headerBorder} border-[0.5px] p-2`}
					headerStyle={{
						padding: "12px",
						border: `0.5px solid ${
							state?.isDay ? "rgba(117, 117, 117, 0.5)" : "#171e25"
						}`,
					}}></Column>
				<Column
					field="description"
					header="Description"
					className={`${siteTheme.headerBorder} border-[0.5px] p-2`}
					headerStyle={{
						padding: "12px",
						border: `0.5px solid ${
							state?.isDay ? "rgba(117, 117, 117, 0.5)" : "#171e25"
						}`,
					}}></Column>
			</DataTable>
		</div>
	);
};

export default TableComponent;
