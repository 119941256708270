import React from 'react'
import { BiLinkExternal } from "react-icons/bi";

import { useAppContext } from '../context/ThemeContext';
import { Link } from 'react-router-dom';

const AchievementComponent = ({achievement}) => {
   const { siteTheme, state } = useAppContext();
  return (
      <div className={`flex flex-col items-start gap-4 mb-3 ${siteTheme.cardBackground} break-inside-avoid-column p-5 rounded-2xl`}>
        <div className="flex flex-row items-center gap-4">
            <div className={`w-16 h-16 ${siteTheme.headingBackground} rounded-full`}>
               <img src={achievement.appLogo} alt={achievement.application} className="w-full h-full" />
            </div>
            <div className="">
               <p className={`text-base font-saira ${siteTheme.textColor}`}>{achievement.application}</p>
               <Link to={achievement.link} target='_blank' className="flex flex-row items-center">
                  <span className="mr-1 text-xs text-blue-500 font-saira">View in Apple Store</span>
                  <BiLinkExternal size={12} className="text-blue-500" />
               </Link>
            </div>
         </div>
         <div>
            <p className={`${state.isDay ? siteTheme.textColor : "text-neutral-400"} font-light text-[14px] tracking-[0.08em] leading-loose font-saira`}>{achievement.description}</p>
         </div>
      </div>
  )
}

export { AchievementComponent }
export default AchievementComponent
