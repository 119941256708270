import React from 'react'
import TechStacks from './TechStacks'

const TechStackGroup = ({data, widthAndheight, gap}) => {
  const isLongList = data?.length > 4;
  return (
    <div className={`${gap ? gap : "gap-4"} ${isLongList ? "flex-wrap justify-center" : ""} relative flex  mx-auto mb-2 max-w-max-content-width`}>
      {data.map( value => (
         <TechStacks key={value.index} value={value} widthAndheight={widthAndheight} isLongList={isLongList} />
      ))}
   </div>
  )
}

export { TechStackGroup }
export default TechStackGroup
