import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link as ScrollLink } from "react-scroll";
import { debounce } from "throttle-debounce";
import { BiLinkExternal } from "react-icons/bi";

import Layout from "../reusable-components/Layout";
import HeadParagraph from "../reusable-components/HeadParagraph";
import { useAppContext } from "../context/ThemeContext";
import { mobileMaxWidth } from "../constants";
import { Link } from "react-router-dom";
import { getAllData } from "../utilities/firebase-connection";
import { PlaceholderBar } from "../reusable-components/PlaceholderBar";

function ClientServicesAgreement() {
	const { siteTheme, state } = useAppContext();
	const [showIcon, setShowIcon] = useState(false);
	const [loading, setLoading] = useState(false);
	const [csa, setCsa] = useState([]);

	useEffect(() => {
		const handleScroll = debounce(200, () => {
			const windowWidth = window.innerWidth;
			let cap = 2000;
			if (windowWidth <= mobileMaxWidth) cap = 9000;
			const scrollOffset = window.scrollY || window.pageYOffset;
			if (scrollOffset < cap) setShowIcon(false);
			if (scrollOffset > cap) setShowIcon(true);
		});

		let loadId;
		(async () => {
			setLoading(true);
			const csaData = await getAllData("csa-sow");
			setCsa(csaData[0]);

			loadId = setTimeout(() => {
				setLoading(false);
			}, 2000);
		})();

		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
			clearTimeout(loadId);
		};
	}, []);

	return (
		<>
			<Helmet>
				<title>Wiingr | Client Services Agreement</title>
			</Helmet>
			<Layout>
				<main
					id="main"
					className="relative h-[100vh] px-4 pt-16 pb-32 mx-auto font-saira sm:pt-24 max-w-max-content-width overflow-scroll no-scrollbar">
					{showIcon && (
						<ScrollLink
							to="main"
							smooth={true}
							duration={500}
							offset={-100}
							className="cursor-pointer">
							<div
								className={[
									"fixed right-4 bottom-20 sm:right-12 sm:bottom-28 rounded-full w-6 h-6 p-1",
									siteTheme.headingBackground,
								].join(" ")}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth={1.5}
									stroke="currentColor"
									className={[
										"w-full h-full",
										state.isDay ? "text-brandBlack" : "text-brandWhite",
									].join(" ")}>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="m4.5 15.75 7.5-7.5 7.5 7.5"
									/>
								</svg>
							</div>
						</ScrollLink>
					)}

					<h1 className="pt-5 text-3xl font-normal font-saira">
						Client Services Agreement
					</h1>
					{loading ? (
						<div>
							<div className="mt-5">
								<PlaceholderBar classname="w-1/4 h-[20px] mb-1 placeholder" />
								<PlaceholderBar classname="w-2/4 h-[20px] mb-1 placeholder" />
								<PlaceholderBar classname="w-4/6 h-[20px] mb-1 placeholder" />
								<PlaceholderBar classname="w-full h-[20px] mb-1 placeholder" />
								<PlaceholderBar classname="w-full h-[20px] mb-1 placeholder" />
							</div>

							<div className="mt-5">
								<PlaceholderBar classname="w-1/4 h-[20px] mb-1 placeholder" />
								<PlaceholderBar classname="w-2/4 h-[20px] mb-1 placeholder" />
								<PlaceholderBar classname="w-4/6 h-[20px] mb-1 placeholder" />
								<PlaceholderBar classname="w-full h-[20px] mb-1 placeholder" />
								<PlaceholderBar classname="w-full h-[20px] mb-1 placeholder" />
							</div>

							<div className="mt-5">
								<PlaceholderBar classname="w-1/4 h-[20px] mb-1 placeholder" />
								<PlaceholderBar classname="w-2/4 h-[20px] mb-1 placeholder" />
								<PlaceholderBar classname="w-4/6 h-[20px] mb-1 placeholder" />
								<PlaceholderBar classname="w-full h-[20px] mb-1 placeholder" />
								<PlaceholderBar classname="w-full h-[20px] mb-1 placeholder" />
							</div>
						</div>
					) : (
						<section className="pb-5">
							<HeadParagraph
								HeadComponent={() => (
									<h2 className="pt-2 text-xl font-saira">
										<span className="font-thin">Service:</span>{" "}
										{csa?.deliverable ? csa?.deliverable : " ..."}
										<br />
										<span className="font-thin">Effective Date:</span>{" "}
										{csa?.effectiveDate ? csa?.effectiveDate : " ..."}
										<br />
										<span className="font-thin">Between:</span> Wiingr Nexus
										("Service Provider")
										<br />
										<span className="font-thin">And:</span>{" "}
										{csa?.client ? csa?.client : " ..."}
									</h2>
								)}
								ParagraphComponent={() => (
									<>
										<p className="pt-5 text-base font-thin ">
											<strong> 1. Project Scope & Deliverables </strong>
											<br />
											Wiingr Nexus agrees to design, develop, and deliver web
											and/or mobile applications as outlined in the attached
											Project Proposal / Statement of Work (SOW).
											<br />
											Changes beyond the initial scope require written approval
											and may impact the timeline and costs.
										</p>
										<p className="pt-5 text-base font-thin ">
											<strong>2. Project Timeline </strong>
											<br />
											Estimated project duration:{" "}
											<strong>{csa?.timeline ? csa?.timeline : " ..."}</strong>
											<br />
											Both parties agree to cooperate in meeting milestones and
											deadlines.
										</p>
										<div className="pt-5 text-base font-thin ">
											<strong>3. Fees & Payment Terms </strong>
											<ul className="pt-3 text-base font-thin list-inside">
												<li className="list-[disc]">
													Total Project Fee:{" "}
													<strong>
														CAD${csa?.charge ? csa?.charge : " ***"}
													</strong>
												</li>
												<li className="list-[disc]">
													Payment Schedule:
													<ul className="ml-4 text-base font-thin list-inside">
														<li className="list-[disc]">
															<strong>
																{csa?.paymentSchedule?.signing
																	? `${csa?.paymentSchedule?.signing}%`
																	: "[X%]"}
															</strong>{" "}
															deposit upon signing
														</li>
														<li className="list-[disc]">
															<strong>
																{csa?.paymentSchedule?.approval
																	? `${csa?.paymentSchedule?.approval}%`
																	: "[X%]"}
															</strong>{" "}
															upon design approval
														</li>
														<li className="list-[disc]">
															<strong>
																{csa?.paymentSchedule?.complete
																	? `${csa?.paymentSchedule?.complete}%`
																	: "[X%]"}
															</strong>{" "}
															upon project completion / before final delivery
														</li>
													</ul>
												</li>
											</ul>
											<p className="pt-5 text-base font-thin ">
												Payment is due within [15/30] days of invoicing. Late
												payments may accrue a 1.5% monthly interest or the
												maximum allowed by law.
											</p>
										</div>
										<div className="pt-5 text-base font-thin ">
											<strong>4. Ownership & Intellectual Property </strong>
											<ul className="pt-3 text-base font-thin list-inside">
												<li className="list-[disc]">
													Upon final payment, the Client owns the rights to the
													final deliverables.
												</li>
												<li className="list-[disc]">
													Wiingr Nexus retains ownership of any pre-existing
													materials, tools, libraries, or frameworks used in
													development.
												</li>
												<li className="list-[disc]">
													The Company may display non-confidential work in
													portfolios and marketing unless a written NDA is
													signed.
												</li>
											</ul>
										</div>
										<div className="pt-5 text-base font-thin ">
											<strong>5. Client Responsibilities </strong>
											<ul className="pt-3 text-base font-thin list-inside">
												<li className="list-[disc]">
													Provide necessary content, materials, and feedback in
													a timely manner.
												</li>
												<li className="list-[disc]">
													Ensure all provided assets are copyright-cleared.
												</li>
												<li className="list-[disc]">
													Review and approve deliverables promptly.
												</li>
											</ul>
										</div>
										<p className="pt-5 text-base font-thin ">
											<strong>6. Confidentiality </strong>
											<br />
											Both parties agree to maintain confidentiality of
											sensitive business information. This obligation remains in
											effect after project completion.
										</p>
										<div className="pt-5 text-base font-thin ">
											<strong>7. Warranties & Limitations</strong>
											<br />
											<ul className="pt-3 text-base font-thin list-inside">
												<li className="list-[disc]">
													Wiingr Nexus warrants that deliverables meet the
													agreed specifications.
												</li>
												<li className="list-[disc]">
													No guarantee is made that software will be error-free
													or work uninterrupted.
												</li>
												<li className="list-[disc]">
													Liability is limited to the amount paid by the Client.
													We are not responsible for indirect or consequential
													losses.
												</li>
											</ul>
										</div>
										<p className="pt-5 text-base font-thin ">
											<strong>8. Third-Party Services</strong>
											<br />
											Any third-party tools, APIs, plugins, or services
											integrated are subject to their respective terms. Wiingr
											Nexus is not liable for third-party issues or downtimes.
										</p>
										<p className="pt-5 text-base font-thin ">
											<strong>9. Termination</strong>
											<br />
											Either party may terminate this agreement with [15/30]
											days' written notice.
											<br />
											The Client will be invoiced for all completed work up to
											termination.
										</p>
										<p className="pt-5 text-base font-thin ">
											<strong>10. Governing Law</strong>
											<br />
											This Agreement is governed by the laws of Toronto, Canada.
											<br />
											Disputes will be handled first through good faith
											negotiations, then arbitration if necessary.
										</p>
										<p className="pt-5 text-base font-thin ">
											<strong>11. Entire Agreement</strong>
											<br />
											This document, together with the signed SOW, constitutes
											the full agreement between both parties and supersedes any
											prior agreements.
										</p>
										<p className="pt-5 text-base font-thin ">
											<strong>Contact Information</strong>
											<br />
											For questions regarding these Client Services Agreement,
											please contact:
											<br />
											<span>
												📧{" "}
												<Link
													to="mailto:info.wiingr@gmail.com"
													className="duration-300 ease-in hover:underline hover:text-blue-600">
													info.wiingr@gmail.com
												</Link>
											</span>
											<br />
											<span>
												📍{" "}
												<Link
													to="https://www.google.com/maps/place/Toronto,+Canada"
													className="duration-300 ease-in hover:underline hover:text-blue-600">
													Toronto, ON. Canada
												</Link>
											</span>
										</p>
									</>
								)}
							/>
							{csa?.files?.length && (
								<div
									className={`mt-5 ${siteTheme.toolBackground} w-fit ${siteTheme.toolText} px-3 py-1 rounded-full text-sm flex flex-row items-center`}>
									<Link to={csa?.files?.[0]} target="_blank">
										Click to download e-copy
									</Link>
									<BiLinkExternal size={12} className="ml-1" />
								</div>
							)}
						</section>
					)}
				</main>
			</Layout>
		</>
	);
}

export default ClientServicesAgreement;
