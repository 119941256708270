import React from "react";
import { Routes, Route } from "react-router-dom";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import HomePage from "./pages/HomePage";
import Profile from "./pages/Profile";
import { AppProvider } from "./context/ThemeContext";
import TermsOfService from "./pages/TermsOfService";
import NexusTermsOfService from "./pages/NexusTermsOfService";
import ClientServicesAgreement from "./pages/ClientServicesAgreement";
import ContactUs from "./pages/ContactUs";
import HireWiingr from "./pages/HireWiingr";
import Contracting from "./pages/Contracting";
import AdminPage from "./pages/AdminPage";
import ErrorPage from "./pages/ErrorPage";
import Loading from "./reusable-components/Loading";
import StatementOfWork from "./pages/StatementOfWork";

function App() {
	const {
		REACT_APP_REPORT_PATH,
		REACT_APP_PORTFOLIO_SLUG,
		REACT_APP_HIRE_SLUG,
	} = process.env;
	const [isNotavailable, setIsNotAvailable] = React.useState(false);

	React.useEffect(() => {
		const availId = setTimeout(() => {
			setIsNotAvailable(true);
		}, 5000);

		return () => {
			clearTimeout(availId);
		};
	}, []);

	return (
		<AppProvider>
			<Routes>
				<Route exact path="/privacy" element={<PrivacyPolicy />} />
				<Route exact path="/terms" element={<TermsOfService />} />
				<Route exact path="/nexus/terms" element={<NexusTermsOfService />} />
				<Route exact path="/nexus/csa" element={<ClientServicesAgreement />} />
				<Route exact path="/nexus/sow" element={<StatementOfWork />} />
				<Route exact path="/nexus/hire-us" element={<HireWiingr />} />
				<Route
					exact
					path={`/nexus/${REACT_APP_HIRE_SLUG}`}
					element={<Contracting />}
				/>
				<Route exact path="/contact-us" element={<ContactUs />} />
				<Route
					exact
					path={`/portfolio/${REACT_APP_PORTFOLIO_SLUG}`}
					element={
						REACT_APP_PORTFOLIO_SLUG ? (
							<Profile />
						) : (
							<Loading isNotavailable={isNotavailable} />
						)
					}
				/>
				<Route
					exact
					path={`/${REACT_APP_REPORT_PATH}`}
					element={<AdminPage />}
				/>
				<Route exact path="*" element={<ErrorPage />} />
				<Route exact path="/" element={<HomePage />} />
			</Routes>
		</AppProvider>
	);
}

export default App;
