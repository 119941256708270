import React from "react";
import { Tooltip } from "react-tooltip";

import { useAppContext } from "../context/ThemeContext";

const TechStacks = ({ value, widthAndheight, isLongList, notRoundImg }) => {
	const { siteTheme } = useAppContext();
	const [showTip, setShowtip] = React.useState(false);

	return (
		<div
			className="relative flex items-center justify-center"
			onMouseOver={() => setShowtip(true)}
			onMouseLeave={() => setShowtip(false)}>
			{notRoundImg ? (
				<div
					className={`overflow-hidden relative rounded-lg ${
						widthAndheight ? widthAndheight : "w-20 h-20 md:w-24 md:h-24"
					}`}>
					<img
						src={value?.src}
						alt={value?.name || value?.text}
						className={`object-cover w-full h-full ${
							notRoundImg ? "" : " p-2 sm:p-3"
						}`}
					/>
					<div
						className={`${siteTheme.background} px-2 py-1 absolute z-10 bottom-0`}>
						<p
							className={`${siteTheme.textColor} text-[10px] tracking-[0.1em] uppercase font-thin font-saira`}>
							{value?.title}
						</p>
					</div>
				</div>
			) : (
				<div
					className={`overflow-hidden bg-white rounded-full ${
						widthAndheight ? widthAndheight : "w-20 h-20 md:w-24 md:h-24"
					}`}>
					<img
						src={value?.src}
						alt={value?.name || value?.text}
						className={`object-cover w-full h-full ${
							isLongList ? "p-[8px]" : "p-2 sm:p-3"
						} rounded-full`}
					/>
				</div>
			)}
			{showTip && (
				<div
					className={`absolute ${
						siteTheme?.toolBackground
					} px-2 py-1 rounded-md ${
						notRoundImg ? "bottom-[50%]" : "-bottom-5"
					} shadow-md z-10 flex flex-nowrap`}>
					<button
						data-tooltip-id="my-tooltip"
						data-tooltip-content=""
						className={`text-xs ${siteTheme.toolText}`}>
						{value?.name || value?.text}
					</button>

					<Tooltip id="my-tooltip" place="" effect="solid" />
				</div>
			)}
		</div>
	);
};

export default TechStacks;
