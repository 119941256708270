const darkTheme = {
	headingBackground: "bg-brandBlack",
	headerBorder: "border-lightGrey",
	headerTextColor: "text-lightGrey",
	background: "bg-brandDarkBackground",
	textColor: "text-brandWhite",
	textLightColor: "text-brandBlack",
	borderColor: "border-linesLight",
	linkColor: "text-highlight",
	borderBannerColor: "border-cyan-500",
	shapeBackground: "bg-shapeBackground",
	toolText: "text-brandBlack",
	toolBackground: "bg-brandWhite",
	cardBackground: "bg-linesFaint",
};

const lightTheme = {
	headingBackground: "bg-brandWhite",
	headerBorder: "border-linesLight",
	headerTextColor: "text-brandBlack",
	background: "bg-brandWhiteBackground",
	textColor: "text-brandBlack",
	textLightColor: "text-basewhitelighter",
	borderColor: "border-linesFaint",
	linkColor: "text-brand",
	borderBannerColor: "border-indigo-600",
	shapeBackground: "bg-shapeBackground",
	toolText: "text-brandWhite",
	toolBackground: "bg-brandBlack",
	cardBackground: "bg-linesFaint",
};

export { lightTheme, darkTheme };
