import React from "react";
import { Helmet } from "react-helmet";
import { GoDot } from "react-icons/go";

import Layout from "../reusable-components/Layout";
import { useAppContext } from "../context/ThemeContext";
import { Spiral } from "../reusable-components/Spiral";
import { TechStackGroup } from "../reusable-components/TechStackGroup";
import { database } from "../config";
import { Achievements } from "../reusable-components/Achievements";
import { FloatLinks } from "../reusable-components/FloatLinks";
import { MicroFrontEnd } from "../reusable-components/MicroFrontEnd";
import { TechStackLeftSummary } from "../reusable-components/TechStackLeftSummary";
import { TechStackRightSummary } from "../reusable-components/TechStackRightSummary";

function Profile() {
	const { siteTheme, state } = useAppContext();
	const [techStacks, setTechStacks] = React.useState([]);
	const [achievements, setAchievements] = React.useState([]);
	const [profileSummaries, setProfileSummaries] = React.useState({});
	const [mfe, setMfe] = React.useState({});
	const [profileName, setProfileName] = React.useState("");
	const [otherInfo, setOtherInfo] = React.useState("");

	React.useEffect(() => {
		const unsubscribe = database
			.collection("tech-stacks")
			.onSnapshot((snapshot) => {
				snapshot.docs.forEach((doc) => {
					if (doc.data().slug) {
						setProfileSummaries(doc.data().profile);
						setTechStacks(doc.data().stackData);
						setProfileName(doc.data().name);
						setAchievements(doc.data().achievements);
						setOtherInfo(doc.data().props);
						setMfe(doc.data().mfe);
					}
				});
			});
		return () => unsubscribe();
	}, []);

	return (
		<>
			<Helmet>
				<title>Portfolio | {profileName}</title>
			</Helmet>
			<Layout profileName={profileName}>
				<div className="relative w-full h-full overflow-x-hidden">
					<div
						className={`${
							state.isDay ? "bg-[rgba(0,0,0,0.05)]" : "bg-[rgba(0,0,0,0.3)]"
						} relative w-auto h-[700px] -ml-[40em] -mr-[40em] rounded-br-[50%] rounded-bl-[50%]`}>
						<div className="relative flex w-[30%] md:w-[40%] lg:w-[45%] xl:w-full px-4 pt-32 pb-20 mx-auto max-w-max-content-width md:justify-center">
							<div className="relative flex items-center justify-end w-full h-[400px] ">
								<div className="absolute bottom-0 left-0 right-0 z-20">
									<span
										className={`${siteTheme.textColor} absolute top-[23%] left-20 text-2xl tracking-[0.5em] font-thin drop-shadow-[0_0_5px_#ffea00]`}>
										...think
									</span>
									<p className="text-[276px] text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-pink-500 h-[180px] leading-none opacity-[0.3] font-thin drop-shadow-[0_0_10px_#ffea00]">
										innovation
									</p>
								</div>
								<Spiral siteTheme={siteTheme} />
							</div>
						</div>
					</div>
					<div className="flex flex-col items-center justify-center w-full mx-auto mt-48 max-w-max-content-width">
						<div className="flex items-center justify-center w-full mx-auto">
							<div className="w-[50%] h-[0.5px] bg-gradient-to-r from-transparent to-linesLight" />
							<GoDot
								size={20}
								color="rgba(117, 117, 117, 0.5)"
								className="mx-2"
							/>
							<div className="w-[50%] h-[0.5px] bg-gradient-to-r to-transparent from-linesLight" />
						</div>
					</div>
					<TechStackRightSummary
						profileSummaries={profileSummaries}
						siteTheme={siteTheme}
						state={state}
						techStacks={techStacks}
					/>
					<TechStackLeftSummary
						profileSummaries={profileSummaries}
						siteTheme={siteTheme}
						state={state}
						techStacks={techStacks}
					/>
					<div className="flex flex-col items-center justify-center w-full px-5 mx-auto mb-40 max-w-max-content-width lg:px-0">
						<TechStackGroup
							data={techStacks.slice(20, techStacks.length)}
							widthAndheight="w-16 h-16"
							gap="gap-5 lg:gap-12"
						/>
					</div>
					<MicroFrontEnd state={state} mfe={mfe} siteTheme={siteTheme} />
					<Achievements achievements={achievements} siteTheme={siteTheme} />
					<FloatLinks otherInfo={otherInfo} siteTheme={siteTheme} />
				</div>
			</Layout>
		</>
	);
}

export default Profile;
