import React, { createContext, useReducer, useContext } from "react";
import { darkTheme, lightTheme } from "../theme";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
	const { REACT_APP_AUTO_SUGGEST_THEME } = process.env;
	const isDaytime = () => {
		const currentHour = new Date().getHours();
		return (
			// Assuming daytime is between 6 AM and 6 PM || Also controlling this with env variable
			currentHour >= 7 &&
			currentHour < 19 &&
			REACT_APP_AUTO_SUGGEST_THEME === "true"
		);
	};

	const initialState = {
		isDay: isDaytime(),
	};

	const reducer = (state, action) => {
		switch (action.type) {
			case "SET_DAY":
				return { ...state, isDay: action.payload };

			default:
				return state;
		}
	};

	const [state, dispatch] = useReducer(reducer, initialState);

	let siteTheme = darkTheme;
	let isDay = state.isDay;

	if (JSON.parse(sessionStorage.getItem("isDay"))) {
		isDay = JSON.parse(sessionStorage.getItem("isDay"));
	}

	if (isDay) siteTheme = lightTheme;

	return (
		<AppContext.Provider value={{ siteTheme, state, dispatch }}>
			{children}
		</AppContext.Provider>
	);
};

export const useAppContext = () => {
	return useContext(AppContext);
};
