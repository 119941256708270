import React from "react";
import { PlaceholderBar } from "./PlaceholderBar";

const Placeholder = ({ siteTheme }) => {
	return (
		<div
			className={`flex flex-col items-start mb-3 ${siteTheme.cardBackground} break-inside-avoid-column p-5 w-[430px] rounded-2xl`}>
			<div className="flex flex-row items-center justify-between w-full">
				<PlaceholderBar classname="w-1/2 h-[20px] placeholder" />
				<PlaceholderBar classname="w-1/4 h-[10px] placeholderSmall" />
			</div>
			<PlaceholderBar classname="w-2/3 h-[20px] mt-1 placeholder" />
			<PlaceholderBar classname="w-1/2 h-[10px] mt-3 placeholderSmall" />
			<PlaceholderBar classname="w-1/3 h-[10px] mt-2 placeholderSmall" />
			<PlaceholderBar classname="w-1/3 h-[10px] mt-2 placeholderSmall" />
			<PlaceholderBar classname="w-full h-[20px] mt-4 placeholder" />
		</div>
	);
};

export { Placeholder };
export default Placeholder;
