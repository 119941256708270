import React, { useEffect, useState } from "react";
import { debounce } from "throttle-debounce";
import loadable from "@loadable/component";

import { useAppContext } from "../context/ThemeContext";
import {
	hirePathname,
	contractingPathname,
	nexusterms,
	clientServicesAgreement,
	statementOfWork,
} from "../constants";

const HeaderComponent = loadable(() => import("./HeaderComponent"));
const HireHeaderComponent = loadable(() => import("./HireHeaderComponent"));
const FooterComponent = loadable(() => import("./FooterComponent"));
const HireFooterComponent = loadable(() => import("./HireFooterComponent"));

function Layout({ children, profileName }) {
	const pathName = window.location.pathname;
	const { siteTheme, state } = useAppContext();
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = debounce(200, () => {
			setWindowWidth(window.innerWidth);
		});

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	let isDay = state.isDay;
	if (JSON.parse(sessionStorage.getItem("isDay"))) {
		isDay = JSON.parse(sessionStorage.getItem("isDay"));
	}

	if (
		pathName === hirePathname ||
		pathName === contractingPathname ||
		pathName === nexusterms ||
		pathName === clientServicesAgreement ||
		pathName === statementOfWork ||
		pathName.includes("portfolio")
	) {
		return (
			<div className="mx-auto ">
				<HireHeaderComponent
					isPortfolio={pathName.includes("portfolio")}
					profileName={profileName}
					siteTheme={siteTheme}
					isDay={isDay}
				/>
				<div className={[siteTheme?.background, siteTheme.textColor].join(" ")}>
					{children}
				</div>
				<HireFooterComponent siteTheme={siteTheme} />
			</div>
		);
	}

	return (
		<div className="mx-auto ">
			<HeaderComponent
				siteTheme={siteTheme}
				isDay={isDay}
				windowWidth={windowWidth}
			/>
			<div className={[siteTheme?.background, siteTheme.textColor].join(" ")}>
				{children}
			</div>
			<FooterComponent siteTheme={siteTheme} />
		</div>
	);
}

export default Layout;
