import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

export const firebaseConfig = {
  apiKey: "AIzaSyBxvi_vgpYbBsJqQ9SYgCZGhS2HnD2-vyI",
  authDomain: "b-and-g-luxury-empire.firebaseapp.com",
  databaseURL: "https://b-and-g-luxury-empire-default-rtdb.firebaseio.com",
  projectId: "b-and-g-luxury-empire",
  storageBucket: "b-and-g-luxury-empire.appspot.com",
  messagingSenderId: "674671280078",
  appId: "1:674671280078:web:d0f8cbd573adf857ecf59f",
  measurementId: "G-3FHQWQVFJD"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
const app = firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const database = firebase.firestore();

export { auth, database, app, firebase as default };
