import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import ClipLoader from "react-spinners/ClipLoader";
import { Field, Form, Formik } from "formik";

import Layout from "../reusable-components/Layout";
import { getAllData } from "../utilities/firebase-connection";
import ContractingReqeustComponent from "../reusable-components/ContractingRequest";
import { useAppContext } from "../context/ThemeContext";
import firebase, { app, database } from "../config";
import { Placeholder } from "../reusable-components/Placeholder";

function Contracting() {
	const fileInputRef = useRef(null);
	const { siteTheme, state } = useAppContext();
	const [sending, setSending] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [allContracting, setAllContracting] = React.useState([]);
	const [progress, setProgress] = React.useState(0);
	const [uploadStatus, setUploadStatus] = React.useState("");
	const [files, setFiles] = React.useState("");

	React.useEffect(() => {
		setLoading(true);
		let fetchId;

		async function fetchData() {
			const reportsData = await getAllData("hire-requests");
			setAllContracting(reportsData);

			fetchId = setTimeout(() => {
				setLoading(false);
			}, 2000);
		}

		fetchData();
		return () => {
			clearTimeout(fetchId);
		};
	}, []);

	const handleFileChange = (e) => {
		const selectedFile = Array.from(e.target.files);

		const validFiles = selectedFile.filter(
			(file) =>
				file.type === "application/pdf" ||
				file.type.includes("document") ||
				file.name.endsWith(".docx") ||
				file.name.endsWith(".doc")
		);

		if (validFiles?.length > 0) {
			setFiles(selectedFile);
		} else {
			alert("Please upload a PDF or document file.");
			e.target.value = null;
		}
	};

	async function handleRequest(value) {
		setSending(true);
		const paymentSchedule = {
			approval: value.approval,
			signing: value.signing,
			complete: value.complete,
		};
		const timelineMilestone = {
			project: {
				hour: value.projecthour,
				description: value.projectdescription,
			},
			frontend: {
				hour: value.frontendhour,
				description: value.frontenddescription,
			},
			backend: {
				hour: value.backendhour,
				description: value.backenddescription,
			},
			api: {
				hour: value.apihour,
				description: value.apidescription,
			},
			testingDebugging: {
				hour: value.testinghour,
				description: value.testingdescription,
			},
			documentation: {
				hour: value.documentationhour,
				description: value.documentationdescription,
			},
		};

		try {
			const uploadPromises = files.map((file, index) => {
				const storageRef = app
					?.storage()
					?.ref(`csa-sow/${Date.now()}-${file.name}`);
				const metadata = { contentType: file.type };

				return new Promise((resolve, reject) => {
					storageRef.put(file, metadata).on(
						"state_changed",
						(snapshot) => {
							const progress = Math.round(
								(snapshot.bytesTransferred / snapshot.totalBytes) * 100
							);
							setProgress(progress.toFixed(0));
						},
						(error) => {
							reject(error);
							setProgress(0);
						},
						async () => {
							const downloadURL = await storageRef.getDownloadURL();
							resolve(downloadURL);
						}
					);
				});
			});

			const totalHour =
				value.projecthour +
				value.frontendhour +
				value.backendhour +
				value.apihour +
				value.testinghour +
				value.documentationhour;
			const charge = totalHour * value.perHourRate;

			Promise.all(uploadPromises)
				.then(async (downloadURLs) => {
					await database.collection("csa-sow").add({
						charge: charge,
						client: value.client,
						deliverable: value.deliverable,
						timeline: value.timeline,
						effectiveDate: value.effectiveDate,
						paymentSchedule: paymentSchedule,
						files: downloadURLs,
						totalHour: totalHour,
						perHourRate: value.perHourRate,
						timelineMilestone: timelineMilestone,
						terminationNoticePeriod: value.terminationNoticePeriod,
						timestamp: firebase.firestore.FieldValue.serverTimestamp(),
					});

					setSending(false);
					setUploadStatus("successful");
					fileInputRef.current.value = "";
				})
				.catch((error) => {
					console.error("Upload failed:", error);
					setSending(false);
				});
			// Ensure data is saved before setting `setSending(false)`
			setSending(false);
			setUploadStatus("successful");
		} catch (error) {
			console.error("Error saving request:", error);
			setSending(false); // Ensure we stop sending even if there's an error
		}
	}

	React.useEffect(() => {
		if (uploadStatus) {
			const submittedId = setTimeout(() => {
				setUploadStatus("");
				setProgress(0);
				fileInputRef.current.value = "";
				setFiles("");
			}, 3000);

			return () => clearTimeout(submittedId);
		}
	}, [uploadStatus]);

	const override = {
		display: "block",
		margin: "0 auto",
		borderColor: state.isDay ? "text-brandwhite" : "text-deepGrey",
	};

	return (
		<>
			<Helmet>
				<title>Wiingr | Contracting</title>
			</Helmet>
			<Layout>
				<div className="flex flex-col items-center lg:flex-row lg:items-start font-saira mx-auto max-w-max-content-width hfull lg:h-[100vh] px-4 py-24 font-thin gap-2 overflow-hidden">
					<div
						className={`${siteTheme.textColor} h-[100%] md:h-full flex-1 md:flex-[0.5] lg:flex-[0.35] overflow-scroll gap-y-2 no-scrollbar border-[0.5px] border-neutral-700 rounded-2xl bg-[rgba(250,250,250,0.01)] px-1 pb-4 `}>
						<div className="py-2">
							<p className="text-center ">Create CSA & SOW</p>
						</div>
						<Formik
							initialValues={{
								client: "",
								charge: "",
								signing: "",
								apihour: "",
								approval: "",
								timeline: "",
								complete: "",
								backendhour: "",
								deliverable: "",
								projecthour: "",
								testinghour: "",
								perHourRate: "",
								frontendhour: "",
								effectiveDate: "",
								apidescription: "",
								documentationhour: "",
								projectdescription: "",
								backenddescription: "",
								testingdescription: "",
								frontenddescription: "",
								terminationNoticePeriod: "",
								documentationdescription: "",
							}}
							onSubmit={async (values, { resetForm }) => {
								handleRequest(values);
								resetForm();
							}}>
							{({ errors, touched }) => (
								<Form className="flex flex-col gap-y-1">
									<Field
										id="effectiveDate"
										name="effectiveDate"
										placeholder="--- Effective Date ---"
										type="text"
										className={`p-2 border-[0.5px] placeholder:text-center focus:ring-0 focus:border-neutral-600 border-neutral-700 rounded-xl bg-[rgba(250,250,250,0.02)] text-sm ${
											errors.effectiveDate && touched.effectiveDate
												? "border-red-900"
												: "border-neutral-700"
										}`}
									/>
									<Field
										id="client"
										name="client"
										placeholder="--- Client ---"
										type="text"
										className={`p-2 border-[0.5px] placeholder:text-center focus:ring-0 focus:border-neutral-600 border-neutral-700 rounded-xl bg-[rgba(250,250,250,0.02)] text-sm ${
											errors.client && touched.client
												? "border-red-900"
												: "border-neutral-700"
										}`}
									/>

									<Field
										id="deliverable"
										name="deliverable"
										placeholder="--- Deliverable ---"
										type="text"
										className={`p-2 border-[0.5px] placeholder:text-center focus:ring-0 focus:border-neutral-600 border-neutral-700 rounded-xl bg-[rgba(250,250,250,0.02)] text-sm ${
											errors.deliverable && touched.deliverable
												? "border-red-900"
												: "border-neutral-700"
										}`}
									/>

									<div className="grid grid-cols-2 gap-1">
										<Field
											id="terminationNoticePeriod"
											name="terminationNoticePeriod"
											placeholder="--- Termination Notice ---"
											type="text"
											className={`p-2 border-[0.5px] placeholder:text-center focus:ring-0 focus:border-neutral-600 border-neutral-700 rounded-xl bg-[rgba(250,250,250,0.02)] text-sm ${
												errors.terminationNoticePeriod &&
												touched.terminationNoticePeriod
													? "border-red-900"
													: "border-neutral-700"
											}`}
										/>
										<Field
											id="timeline"
											name="timeline"
											placeholder="--- Time line ---"
											type="text"
											className={`p-2 border-[0.5px] placeholder:text-center focus:ring-0 focus:border-neutral-600 border-neutral-700 rounded-xl bg-[rgba(250,250,250,0.02)] text-sm ${
												errors.timeline && touched.timeline
													? "border-red-900"
													: "border-neutral-700"
											}`}
										/>
									</div>

									<div className="pt-3">
										<p className="text-sm text-center">Payment Schedule</p>
									</div>

									<div className="grid grid-cols-4 gap-1">
										<Field
											id="perHourRate"
											name="perHourRate"
											placeholder="--- Hourly Rate ---"
											type="number"
											className={`p-2 border-[0.5px] placeholder:text-center focus:ring-0 focus:border-neutral-600 border-neutral-700 rounded-xl bg-[rgba(250,250,250,0.02)] text-sm ${
												errors.perHourRate && touched.perHourRate
													? "border-red-900"
													: "border-neutral-700"
											}`}
										/>
										<Field
											id="approval"
											name="approval"
											placeholder="--- Approval ---"
											type="number"
											className={`p-2 border-[0.5px] placeholder:text-center focus:ring-0 focus:border-neutral-600 border-neutral-700 rounded-xl bg-[rgba(250,250,250,0.02)] text-sm ${
												errors.approval && touched.approval
													? "border-red-900"
													: "border-neutral-700"
											}`}
										/>
										<Field
											id="signing"
											name="signing"
											placeholder="--- Signing ---"
											type="number"
											className={`p-2 border-[0.5px] placeholder:text-center focus:ring-0 focus:border-neutral-600 border-neutral-700 rounded-xl bg-[rgba(250,250,250,0.02)] text-sm ${
												errors.signing && touched.signing
													? "border-red-900"
													: "border-neutral-700"
											}`}
										/>
										<Field
											id="complete"
											name="complete"
											placeholder="--- Complete ---"
											type="number"
											className={`p-2 border-[0.5px] placeholder:text-center focus:ring-0 focus:border-neutral-600 border-neutral-700 rounded-xl bg-[rgba(250,250,250,0.02)] text-sm ${
												errors.complete && touched.complete
													? "border-red-900"
													: "border-neutral-700"
											}`}
										/>
									</div>

									<div className="pt-3">
										<p className="text-sm text-center">Timeline & Milestones</p>
									</div>

									<div className="grid grid-cols-2 gap-1">
										<Field
											id="projecthour"
											name="projecthour"
											placeholder="--- Project ---"
											type="number"
											className={`p-2 border-[0.5px] placeholder:text-center focus:ring-0 focus:border-neutral-600 border-neutral-700 rounded-xl bg-[rgba(250,250,250,0.02)] text-sm ${
												errors.projecthour && touched.projecthour
													? "border-red-900"
													: "border-neutral-700"
											}`}
										/>
										<Field
											id="projectdescription"
											name="projectdescription"
											placeholder="--- Description ---"
											type="text"
											className={`p-2 border-[0.5px] placeholder:text-center focus:ring-0 focus:border-neutral-600 border-neutral-700 rounded-xl bg-[rgba(250,250,250,0.02)] text-sm ${
												errors.projectdescription && touched.projectdescription
													? "border-red-900"
													: "border-neutral-700"
											}`}
										/>
									</div>

									<div className="grid grid-cols-2 gap-1">
										<Field
											id="frontendhour"
											name="frontendhour"
											placeholder="--- Frontend ---"
											type="number"
											className={`p-2 border-[0.5px] placeholder:text-center focus:ring-0 focus:border-neutral-600 border-neutral-700 rounded-xl bg-[rgba(250,250,250,0.02)] text-sm ${
												errors.frontendhour && touched.frontendhour
													? "border-red-900"
													: "border-neutral-700"
											}`}
										/>
										<Field
											id="frontenddescription"
											name="frontenddescription"
											placeholder="--- Description ---"
											type="text"
											className={`p-2 border-[0.5px] placeholder:text-center focus:ring-0 focus:border-neutral-600 border-neutral-700 rounded-xl bg-[rgba(250,250,250,0.02)] text-sm ${
												errors.frontenddescription &&
												touched.frontenddescription
													? "border-red-900"
													: "border-neutral-700"
											}`}
										/>
									</div>

									<div className="grid grid-cols-2 gap-1">
										<Field
											id="backendhour"
											name="backendhour"
											placeholder="--- Backend ---"
											type="number"
											className={`p-2 border-[0.5px] placeholder:text-center focus:ring-0 focus:border-neutral-600 border-neutral-700 rounded-xl bg-[rgba(250,250,250,0.02)] text-sm ${
												errors.backendhour && touched.backendhour
													? "border-red-900"
													: "border-neutral-700"
											}`}
										/>
										<Field
											id="backenddescription"
											name="backenddescription"
											placeholder="--- Description ---"
											type="text"
											className={`p-2 border-[0.5px] placeholder:text-center focus:ring-0 focus:border-neutral-600 border-neutral-700 rounded-xl bg-[rgba(250,250,250,0.02)] text-sm ${
												errors.backenddescription && touched.backenddescription
													? "border-red-900"
													: "border-neutral-700"
											}`}
										/>
									</div>

									<div className="grid grid-cols-2 gap-1">
										<Field
											id="apihour"
											name="apihour"
											placeholder="--- Api ---"
											type="number"
											className={`p-2 border-[0.5px] placeholder:text-center focus:ring-0 focus:border-neutral-600 border-neutral-700 rounded-xl bg-[rgba(250,250,250,0.02)] text-sm ${
												errors.apihour && touched.apihour
													? "border-red-900"
													: "border-neutral-700"
											}`}
										/>
										<Field
											id="apidescription"
											name="apidescription"
											placeholder="--- Description ---"
											type="text"
											className={`p-2 border-[0.5px] placeholder:text-center focus:ring-0 focus:border-neutral-600 border-neutral-700 rounded-xl bg-[rgba(250,250,250,0.02)] text-sm ${
												errors.apidescription && touched.apidescription
													? "border-red-900"
													: "border-neutral-700"
											}`}
										/>
									</div>

									<div className="grid grid-cols-2 gap-1">
										<Field
											id="testinghour"
											name="testinghour"
											placeholder="--- Test & Debug ---"
											type="number"
											className={`p-2 border-[0.5px] placeholder:text-center focus:ring-0 focus:border-neutral-600 border-neutral-700 rounded-xl bg-[rgba(250,250,250,0.02)] text-sm ${
												errors.testinghour && touched.testinghour
													? "border-red-900"
													: "border-neutral-700"
											}`}
										/>
										<Field
											id="testingdescription"
											name="testingdescription"
											placeholder="--- Description ---"
											type="text"
											className={`p-2 border-[0.5px] placeholder:text-center focus:ring-0 focus:border-neutral-600 border-neutral-700 rounded-xl bg-[rgba(250,250,250,0.02)] text-sm ${
												errors.testingdescription && touched.testingdescription
													? "border-red-900"
													: "border-neutral-700"
											}`}
										/>
									</div>
									<div className="grid grid-cols-2 gap-1">
										<Field
											id="documentationhour"
											name="documentationhour"
											placeholder="--- Documentation ---"
											type="number"
											className={`p-2 border-[0.5px] placeholder:text-center focus:ring-0 focus:border-neutral-600 border-neutral-700 rounded-xl bg-[rgba(250,250,250,0.02)] text-sm ${
												errors.documentationhour && touched.documentationhour
													? "border-red-900"
													: "border-neutral-700"
											}`}
										/>
										<Field
											id="documentationdescription"
											name="documentationdescription"
											placeholder="--- Description ---"
											type="text"
											className={`p-2 border-[0.5px] placeholder:text-center focus:ring-0 focus:border-neutral-600 border-neutral-700 rounded-xl bg-[rgba(250,250,250,0.02)] text-sm ${
												errors.documentationdescription &&
												touched.documentationdescription
													? "border-red-900"
													: "border-neutral-700"
											}`}
										/>
									</div>

									<input
										ref={fileInputRef}
										type="file"
										multiple
										accept=".pdf,.doc,.docx"
										onChange={handleFileChange}
										className={`block w-full text-sm file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold ${
											state.isDay
												? "file:bg-brandWhite file:text-brandBlack"
												: "file:bg-linesLighter file:text-brandWhite"
										} w-full`}
									/>
									<div className="w-full my-2 text-sm text-blue-400 lg:my-0 lg:ml-5">
										{progress > 0 && uploadStatus === "successful" && (
											<p>
												{progress === 100 ? "Uploaded:" : "Uploading:"}{" "}
												{progress}% {uploadStatus}
											</p>
										)}
									</div>

									{sending ? (
										<ClipLoader
											color={state.isDay ? "text-brandBlack" : "text-lightGrey"}
											loading={sending}
											cssOverride={override}
											size={20}
											aria-label="Loading Spinner"
											data-testid="loader"
										/>
									) : (
										<div className="w-full mx-auto">
											<button
												disabled={files?.length === 0}
												type="submit"
												className={`py-2 w-full px-5 rounded-lg ${
													state.isDay
														? "bg-brandBlack text-lightGrey"
														: "bg-lightGrey text-brandBlack"
												}`}>
												Submit
											</button>
										</div>
									)}
								</Form>
							)}
						</Formik>
					</div>
					<div className="flex-1 md:flex-[0.5] lg:flex-[0.65] h-full">
						{loading ? (
							<Placeholder siteTheme={siteTheme} />
						) : (
							<div className="mx-auto columns-1 md:columns-2">
								{allContracting.map((request) => (
									<ContractingReqeustComponent
										key={allContracting[0]?.id}
										request={allContracting[0]}
									/>
								))}
							</div>
						)}
					</div>
				</div>
			</Layout>
		</>
	);
}

export default Contracting;
